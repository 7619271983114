<template>
  <div class="main">
    <div style="min-height: 700px; padding-top: 20px; background: #fff">
      <div class="itemName">
        <el-divider>
          <div class="dividerFont">
            <span>专场招聘</span>
            <div
              style="width: 60px; height: 2px; background-color: #029aff"
            ></div>
          </div>
        </el-divider>
      </div>
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <!-- <el-table-column align="center" type="selection" min-width="5%">
        </el-table-column> -->
        <el-table-column
          align="center"
          prop="title"
          label="招聘会名称"
          min-width="35%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="address"
          label="举办场馆"
          min-width="25%"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="begin_time"
          label="开始时间"
          min-width="20%"
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.begin_time  ?   scope.row.begin_time.substring(0,16) : ''
            }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" min-width="15%">
          <div
            class="viewDetails"
            slot-scope="scope"
            @click="toDetail(scope.row)"
          >
            <img
              src="@/assets/personCenter/icon_chakanxiangqing_btn_nor.png"
              alt=""
            />
            <span>查看详情</span>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div style="text-align: center; margin-top: 20px">
      <el-pagination
        :background="true"
        layout="prev, pager, next"
        :total="total"
        :page-size="10"
        @current-change="chengePagination"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getjobFairPageByUser } from "@/api/jobFair/jobFair";
import moment from 'moment'
export default {
  name: "",
  data() {
    return {
      total: 1,
      tableData: [],
      multipleSelection: "",
      bTime: null,
      eTime: null,
      params: {
        pageNo: 1,
        pageSize: 10,
        jobUserId: "",
      },
    };
  },
  watch: {},
  props: {},
  components: {},

  computed: {},
  created() {
    this.params.jobUserId = localStorage.getItem("userId");
    this.getjobFairList();
  },
  mounted() {},
  methods: {
    moment,
    // 获取专场招聘列表
    getjobFairList() {
      getjobFairPageByUser(this.params).then((res) => {
        res.data.rows.sort(
          (a, b) =>
            new Date(b.begin_time).getTime() - new Date(a.begin_time).getTime()
        ); //这是升序，倒序的话翻过来
        this.tableData = res.data.rows;
        this.total = res.data.totalRows;
      });
    },

    // 翻页
    chengePagination(cur) {
      this.params.pageNo = cur;
      this.getjobFairList();
    },
    timeMoment(val){
     let nowTime =  moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
     let endTime =  moment(val.end_time).format('YYYY-MM-DD HH:mm:ss')
     if(nowTime > endTime){
      return true
     }else{
      return false
     }
    },
    toDetail(val) {
      if(this.timeMoment(val)){
        this.$router.push({
          path: "/jobFairDetails",
          query: { id: val.id,held:1}
      })
      }else{
        this.$router.push({
          path: "/jobFairDetails",
          query: { id: val.id }
      })
      }

    },

    // handleClick(tab, event) {
    //   console.log(tab, event)
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    // isRemind(e) {
    //   console.log(e)
    //   e.remind = !e.remind
    //   console.log(this.tableData)
    // },
  },
  filters: {},
};
</script>

<style scoped lang="scss">
::v-deep {
  .el-pagination.is-background .btn-prev {
    /*对下一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .btn-next {
    /*对上一页的按钮样式进行修改*/
    background: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体
    // color: #fff;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: rgb(19, 138, 206); // 进行修改选中项背景和字体
    color: rgb(255, 255, 255);
  }
}

.itemName {
  padding: 0 86px;
  padding-bottom: 22px;
  .dividerFont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    span {
      font-size: 24px;
      margin-bottom: 6px;
    }
  }
}

::v-deep .el-tabs {
  margin-top: 10px;
  background-color: #fff;
  .el-tabs__nav-wrap {
    background-color: #fafafa;
    .el-tabs__nav {
      border: none;
      .el-tabs__item {
        border: none;
      }
      .is-active {
        background-color: #fff;
      }
    }
  }
}

.viewDetails {
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
}
</style>
